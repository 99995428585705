@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {
  body {
    background-color: rgb(249 250 251);
  }
  .stepper {
    position: relative;
    border-radius: 5px;
    flex: 100%;
    /* width: 370px; */
    padding: 20px 20px 20px 40px;
  }

  .last {
    /* height: 78px; */
    -webkit-clip-path: polygon(20px 50%, 0% 0%, 100% 0%, 100% 100%, 0% 100%);
  }

  .first {
    -webkit-clip-path: polygon(
      0% 0%,
      calc(100% - 20px) 0%,
      100% 50%,
      calc(100% - 20px) 100%,
      0% 100%
    );
  }

  .center3 {
    position: absolute;
    height: 78px;
    clip-path: polygon(
      18.5px 50%,
      -0.5% 0%,
      calc(100% - 20px) 0%,
      100% 50%,
      calc(100% - 20px) 100%,
      -0.5% 100%
    );
  }

  .center {
    position: relative;
    clip-path: polygon(
      20px 50%,
      0% 0%,
      calc(100% - 20px) 0%,
      100% 50%,
      calc(100% - 20px) 100%,
      0% 100%
    );
  }
  @font-face {
    font-family: "Tajawal-Black";
    src: url("../public/assets/fonts/Tajawal-Black.ttf") format("truetype");
    font-weight: 900;
    font-style: normal;
  }
  @font-face {
    font-family: "Tajawal-Bold";
    src: url("../public/assets/fonts/Tajawal-Bold.ttf") format("truetype");
    font-weight: bold;
    font-style: normal;
  }
  @font-face {
    font-family: "Tajawal-Medium";
    src: url("../public/assets/fonts/Tajawal-Medium.ttf") format("truetype");
    font-weight: 500;
    font-style: normal;
  }
  @font-face {
    font-family: "Tajawal-Regular";
    src: url("../public/assets/fonts/Tajawal-Regular.ttf") format("truetype");
    font-weight: 400;
    font-style: normal;
  }
  @font-face {
    font-family: "Tajawal-Light";
    src: url("../public/assets/fonts/Tajawal-Light.ttf") format("truetype");
    font-weight: 300;
    font-style: normal;
  }
}
